.title {
    text-align: center;
}

p {
    text-indent: 20px;
 }

 .picture {
    width: 40%;
    height: 25%;
    
 }


 .blocpicture {
    text-align: center;
 }
 