.block {
    width: 87%;
    background: linear-gradient(rgb(5, 5, 145), rgb(135, 168, 240));
    margin-left: 10%;
    border-radius: 10px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 1%;

}

.user {
    background-color: white;
    border-radius: 12px;
    margin-left: 5px;
    margin-right: 5px;
    text-align: left;
    color: black;
   
}

li {
    list-style-type: none;
    /* Убираем маркеры */
  }


.getinfo {
    margin-top: 12px;
    font-weight: bolder;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 20px;
    border-radius: 13px;
    padding: 2%;
}

.inputuser {
    font-size: 16px;
    width: 80%;
    height: 30px;
}

.title {
    font-size: 24px;
    text-shadow: 2px 1px black;
    padding-top: 1%;
}

.not {
    color:red;
    font-size: large;
    text-shadow: 1px  white;
}