.block {
    background: linear-gradient(rgb(10, 9, 73), rgb(103, 146, 231));
    width: 87%;
    margin-left: 10%;
    margin-top: -4%;
    border-radius: 10px;
    color: white;
    text-shadow: 2px 1px black;
    padding-bottom: 2px;
    }
    .list {
        list-style-type:none;
        color: white;
        text-align: left;
        font-family:'Times New Roman';
        font-size: 18px;
        
    }

    