.adr {
    color: blue;
}

.list {
    font-size: 1.5rem; /* Используем em/rem для адаптивности */
    list-style-type: none; /* Убираем маркеры */
    padding: 0; /* Убираем отступы */
}

.list li {
    margin-bottom: 15px; /* Добавляем отступ между элементами списка */
}

.telegram {
    color: rgb(55, 117, 209);
}

.viber {
    color: mediumslateblue;
}

/* Основная обертка для контента */
.container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Два столбца одинаковой ширины */
    gap: 20px; /* Расстояние между столбцами */
    align-items: start; /* Выравнивание по верхнему краю */
    margin-top: 20px; /* Отступ сверху */
}

/* Текстовый блок */
.text-content {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Отступ между строками текста */
}

/* Блок с изображениями */
.photo {
    display: flex; /* Используем flexbox для расположения изображений в ряд */
    gap: 20px; /* Расстояние между изображениями */
    justify-content: flex-start; /* Выравнивание по левому краю */
}

.photo img {
    max-width: 50%; /* Масштабируем изображения */
    height: auto; /* Сохраняем пропорции */
    border-radius: 10px; /* Скругляем углы */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Легкая тень */
    transition: transform 0.3s ease; /* Плавное увеличение при наведении */
}

.photo img:hover {
    transform: scale(1.05); /* Легкое увеличение при наведении */
}

