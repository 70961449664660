.template {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content:space-between;
    
}

.block {
    margin-left: 10px;
    margin: 1%;
    border: 1px rgba(0, 0, 0, 0.295) solid;
    border-radius: 10px;
}

.frame {
    width: 90%;
    height: 250px;
    margin: 10px;
}