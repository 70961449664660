.App {
  text-align: center;
}

nav {
  color:white;
}

a{
  text-decoration: none;
  margin-left: 0.5%;
  border: 1px black solid;
  padding: 0.5%;
  color:white;
  border-radius: 8px;
  background-color: #0a1a2d;
  font-size: large;
}


.active {
  color: rgb(237 232 20);
  text-decoration: none;
  
}



.body {
  display: flex;
 
}



.left {
 margin-left: 0.5%;
}

.pays {
  width: 80%;
  cursor: pointer;
}

.card {
  width: 80%;
  margin-left: 10%;
}

.centr {
  text-align: justify;
  width: 70%;
  margin: 0.2%;
  margin-top: 2%;
  max-height: 420px;
  overflow-y:auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.centr::-webkit-scrollbar {
  display: none;
}






.right {
 margin-top: -2%;
 
}

img {
  width: 100%;
}

.title_tarif {
  text-align: center;
}